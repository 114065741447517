// This is a skeleton starter React page generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react"
import { PlasmicHomepage } from "../components/plasmic/stack_input_site/PlasmicHomepage"
import ResourcesTextBox from "../components/ResourcesTextBox"

import { useEffect, useState } from "react";

function Homepage() {

  // Use PlasmicHomepage to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicHomepage are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicHomepage is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Gatsby "wrapRootElement" function
  // (https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr#wrapRootElement).

  const [oneHundredResources, setOneHundredResources] = useState([]);
  const [oneThousandResources, setOneThousandResources] = useState([]);
  // const [topicsArrayUnique, setTopicsArrayUnique] = useState([])

  useEffect(() => {
    var airtable_100_url = "https://api.airtable.com/v0/appldDJDdY8eUHXyx/OneHundredResources?api_key=" + process.env.GATSBY_AIRTABLE_KEY
    fetch(airtable_100_url)
      .then((res) => res.json())
      .then((resources) => {
        setOneHundredResources(resources);

        // var topics_array = []
        // for (let i=0; i < resources.records.length; i++) {
        //   topics_array.push(resources.records[i].fields.Category[0])
        // }
        // var topicsUnique = [...new Set(topics_array)];
        // console.log(topicsUnique)
        // setTopicsArrayUnique(topicsUnique)

      })
      .catch((error) => {
        console.log(error);
      });

      // 1000 other resources
      var airtable_1000_url = "https://api.airtable.com/v0/appldDJDdY8eUHXyx/OneThousandOtherResources?api_key=" + process.env.GATSBY_AIRTABLE_KEY
      fetch(airtable_1000_url)
        .then((res) => res.json())
        .then((resources) => {
          setOneThousandResources(resources);
  
        })
        .catch((error) => {
          console.log(error);
        });
    
  }, []);


  if ((oneHundredResources.records) && (oneThousandResources.records)) {
    return <PlasmicHomepage

      postList={{
        children: oneHundredResources.records.map((item) => {
          return (
            <ResourcesTextBox
              textIn={item.fields.Name.slice(0, 78)}
              linkOut={item.fields.URL}
              category={item.fields.Category}
              image={item.fields.Image[0].url}
            />
          )
        })
      }}

      postList2={{
        children: oneThousandResources.records.map((item) => {
          return (
            <ResourcesTextBox
              textIn={item.fields.Name.slice(0, 78)}
              linkOut={item.fields.URL}
              category={item.fields.Category}
              image={item.fields.Image[0].url}
            />
          )
        })
      }}


    />
  }
  return <PlasmicHomepage />
}

export default Homepage
